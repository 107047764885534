import {Grid, Typography, useMediaQuery} from "@mui/material"
// import logo from './logo.svg';
// import bgImage from './public/NgorongoroCrater.jpg'
import './App.css';

function App() {
  const matches = useMediaQuery('(min-width:600px)');
  console.log(matches)
  return (
    <div className="App">
      {/* <header className="App-header"> */}        
      {/* <img src={logo} className="App-logo" alt="logo" /> */}

        {matches?(<Grid container justifyContent="center" alignItems="center" style={{padding: '4rem', backgroundSize: 'cover',backgroundPosition: 'center',backgroundImage: 'url(https://res.cloudinary.com/dwelloptimal/image/upload/v1637611257/kibira/4_-_Ngorongoro_Crater_16.jpg)', height: '100vh'}} >
          <Grid item>
          <img width="100%" style={{marginBottom: '2rem'}} src={"https://res.cloudinary.com/dwelloptimal/image/upload/v1637620840/kibira/large-white.png"} alt="logo" />
        <Typography variant='h4' style={{fontSize: '2rem', fontFamily: "'Outfit', sans-serif", fontWeight: 100, color:'#fff'}}>Coming Soon<span style={{margin: '0 14px'}}>|</span><a href="mailto:info@kibira.com" style={{color: '#fff', textDecoration: 'none'}}>info@kibira.com</a></Typography>
        

        </Grid></Grid>):(<Grid container justifyContent="center" alignItems="center" style={{padding: '4rem', backgroundSize: 'cover',backgroundPosition: 'center right',backgroundImage: 'url(https://res.cloudinary.com/dwelloptimal/image/upload/v1637622291/kibira/IMG_2017.jpg)', height: '100vh'}} >
          <Grid item>
          <img width="100%" style={{marginBottom: '3rem'}} src={"https://res.cloudinary.com/dwelloptimal/image/upload/c_crop,h_130,w_436/v1637620854/kibira/large.png"} alt="logo" />
        <Typography variant='h4' style={{fontFamily: "'Outfit', sans-serif", fontWeight: 100, color:'rgb(19,34,25)'}}>Coming Soon</Typography>
        <Typography variant='h6' style={{marginBottom: '8rem', fontFamily: "'Outfit', sans-serif", fontWeight: 100, color:'rgb(19,34,25)'}}><a href="mailto:info@kibira.com" style={{color: 'rgb(19,34,25)', textDecoration: 'none'}}>info@kibira.com</a></Typography>

        

        </Grid></Grid>)
        }
      {/* </header> */}
    </div>
  );
}

export default App;
